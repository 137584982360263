var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh", "font-size": "12px" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "7%" } }),
        _c("col", { staticStyle: { width: "10%" } }),
        _c("col"),
        _c("col", { staticStyle: { width: "9%" } }),
        _c("col", { staticStyle: { width: "12%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "8%" } }),
        _c("col", { staticStyle: { width: "7%" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c("b-th", [_vm._v("ID")]),
              _c("b-th", [_vm._v("제약사")]),
              _c("b-th", [_vm._v("제목")]),
              _c("b-th", [_vm._v("게시 기간")]),
              _c("b-th", [_vm._v("진료과")]),
              _c("b-th", [_vm._v("총 클릭 수")]),
              _c("b-th", [
                _vm._v(
                  _vm._s(_vm.searchType === 1 ? "총 클릭 처수" : "총 클릭 명수")
                ),
              ]),
              _c("b-th", [_vm._v("알림창"), _c("br"), _vm._v("제외 수")]),
              _c("b-th", [_vm._v("댓글 수")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        [
          _vm._l(_vm.data, function (summary) {
            return [
              _vm._l(summary.summaryDepts, function (dept, index) {
                return [
                  _c(
                    "b-tr",
                    { key: "summary-" + summary.id + "-" + index },
                    [
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.id) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.clientName) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(summary.title) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: summary.summaryDepts.length + 1,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      summary.startDate,
                                      "YYYY-MM-DD"
                                    )
                                  ) +
                                  " ~"
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    summary.endDate,
                                    "YYYY-MM-DD"
                                  )
                                ) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("b-td", [_vm._v(_vm._s(dept.medicalDept))]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.totalClickCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.groupClickCount)
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.neverAgainViewCount)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.commentCount)
                          ) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
              _c(
                "b-tr",
                { key: "summary-total-" + summary.id },
                [
                  _c("b-td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" 소계 "),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.totalClickCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.groupClickCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.neverAgainViewCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                  _c("b-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("$numberFormatter")(
                          summary.summaryDepts.reduce(function (f, l) {
                            return f + l.commentCount
                          }, 0)
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }